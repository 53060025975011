/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/
.label-phone-eng{
  left: 6%; 
  right: 5%; 
  font-weight: normal;
}
.label-phone-ar{
   
  right: 5%; 
  font-weight: bolder;
}
button:disabled{
  background:gainsboro;
}
button:focus{
  outline: none;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  
}
.container-fluid{
  padding: 0;
  margin: 0;
}
html,
body {
  display: block;
  direction: ltr;
  background: rgb(247, 213, 63);
  font-family: 'Alegreya Sans', sans-serif;
  margin: 0;
}

.my-container {
  background: #9D4E29;
  margin: 0 auto;
}

.header {
  position: relative;
}

.header-img {
  width: 100%;
  height: auto;
}

.lang-btn {
  position: absolute;
  width: 50px;
  height: auto;
  top: 20px;
  right: 15px;
}
.zain-logo {
  position: absolute;
  width: 50px;
  height: auto;
  top: 20px;
  left: 15px;
}
.subscribe {
  margin-top: -30px;
  font-family: 'Alegreya Sans', sans-serif;
}

.sub-btn {

  color: #171c08;
  width: 95%;
  height: 75px;
  font-size: 16px;
  border-radius: 5px 5px 5px 5px;
  background: linear-gradient(to bottom, #f0f256, #8acf32);
  border-style: none;
  border-width: 5px;
  margin-bottom: 10px;

  -webkit-box-shadow: 0px 5px 0px 0px rgba(123, 61, 32, 1);
  -moz-box-shadow: 0px 5px 0px 0px rgba(123, 61, 32, 1);
  box-shadow: 0px 5px 0px 0px rgba(123, 61, 32, 1);
}

.sub-btn div.d1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2em;


}

.sub-btn div.d2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

.moto {
  color: white;
  margin-top: 35px;
  margin-bottom: 10px;
}

.center {
  text-align: center;
}

.heading {
  font-size: 1.5em;
  margin-bottom: 5px;

}

.sub-heading {
  font-size: 1.2em;

}

.footer {
  font-family: 'Open Sans', sans-serif;
  background: white;
  padding: 0 20px 10px 20px;
  border: none;
  margin-top: -5px;
  border-bottom: 1px solid white;
}

.sub-cond-msg {
  color: grey;
}

.copyright {
  color: lightgrey;
}

.hide {
  display: none;
}

.sub-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 600px;
  margin: auto;
}

.sub-section label {
  position: absolute;
  top: 22px;

  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  
}

.sub-section input {
  font-family: 'Open Sans', sans-serif !important;
  background: url(/mobily-digital/images/icon-phone.png) no-repeat scroll 7px 7px;
  margin-top: 15px;
  width: 95%;
  height: 85px;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-left: 65px;
  padding-right: 10px;
  background-color: white;
  background-size: 45px;
  background-position: 10px 25px;
  border: none;
  padding-top: 20px;
  font-size: 18px;
  font-weight: normal;
}

.img-lrg {
  display: none !important;
}


@media (min-width:601px) {
  .my-container {
    width: 100% !important
  }
  .img-small {
    display: none !important;
    width: 100%;
  }
  .img-lrg {
    display: block !important;
    width: 100%;
  }
}

@media (max-width:350px) {
  .sub-section input {
    width: 95% !important;
  }
  .sub-section label {
    font-size: 16px;
  }
}

.gago-main-container{
  background: black;
}

.gago-banner{
  background: url(/images/gago/banner.png) no-repeat scroll 10px 10px;
}
.gago-box{
  color: white;
  height:98%;
  
  background: rgb(80, 6, 80);
  border-radius: 1px;
}
.dummy-row{
  display: flex;
  flex-wrap: wrap;
}
